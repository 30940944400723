import Heading from '@Src/components/ui/Heading';
import React, { useEffect, useState } from 'react';
import Text from '@Src/components/Text';
import * as styles from './index.module.scss';
import { useApp, usePageTheme } from '@Src/context/AppContext';
import cx from 'classnames';
import ContentLayout from '@Src/components/sitestructure/ContentLayout';
import Input from '@Src/components/ui/Input';
import { submitMerchInterest } from '@Src/actions/aloaded';
import { SubmitButton } from '@Src/components/ui/SubmitButton';
import Alert from '@Src/components/ui/Alert';
import BottomStickyFooter from '@Src/components/ui/BottomStickyFooter';

function Impact({ children }: { children: any }) {
  return (
    <div style={{textTransform: 'uppercase', fontSize: '3rem', fontWeight: 'bold', textAlign: 'center'}}>{children}</div>
  )
}
function ImpactText({ children }: { children: any }) {
  return (
    <div style={{textTransform: 'uppercase', fontSize: '2rem', textAlign: 'left', fontWeight: 'bold'}}>{children}</div>
  )
}

export function MerchPage({
  transitionStatus
}: { transitionStatus: any }) {
	const { theme, setTheme: setPageTheme} = usePageTheme();
	const { setPageType, setPageTitle } = useApp();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setSubmitting] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)
  const [isError, setError] = useState(false)

  const handleSubmit = (event: any) => {
    event.preventDefault()
    if (!event.target.checkValidity()) {
      return
    }
    setSubmitting(true)
    // @ts-ignore
    if (window?.dataLayer instanceof Array) {
      // @ts-ignore
      window.dataLayer.push({
        event: 'sign_up',
        signupMethod: 'web',
        eventCallback: () => {
          submitMerchInterest(
            name,
            email
          ).then(result => {
            setSubmitting(false)
            setSubmitted(true)
            window.location.href = `/merchondemand/submitted`
          }).catch(e => {
            console.error(e)
            setSubmitted(false)
            setError(true)
          })
        },
        eventTimeout: 2000
      })
    }
    
    return false;
  }
  const mode = 'light';
	useEffect(() => {
		setPageType('signup');
	}, []);

	useEffect(() => {
		if (transitionStatus == 'entering' || transitionStatus == 'entered') {
			setPageTheme('white');
			setPageTitle('signup');
			//setPageType('index');
		}
	}, [transitionStatus]);

	useEffect(() => {
		if (transitionStatus == 'entered') {
			setPageTheme('light');
		}
	}, [mode]);
  return (
    <ContentLayout fullWidth={true} transitionState={transitionStatus} type="signup">
      <div style={{padding: 0, textTransform: 'uppercase'}}>
        <Text heading01={`Thanks for your inquiry!`} />
        <Text htmlText={`Thank you - we will get in touch with you shortly!`} />
      </div>
    </ContentLayout>
  )
}

export default MerchPage;